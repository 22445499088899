import css from "./HelloWorld.scss?inline";
import globalStyles from "../../index.scss?inline";
import { GreetingType } from "./GreetingType";
import { ArgSpecDictionary } from "../component-utils";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[HHDSHelloWorld]";
export const HHDSHelloWorldTagName: string = "hhds-hello-world";

export class HHDSHelloWorld extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    let greetingText: string = "";
    const greetingType = this.vars.get<GreetingType>("greeting");
    switch (greetingType) {
      case GreetingType.Spanish:
        greetingText = "Hola ";
        break;
      case GreetingType.Japanese:
        greetingText = "こんにちは ";
        break;
      case GreetingType.Formal:
        greetingText = "Good day ";
        break;
      case GreetingType.Friendly:
      default:
        greetingText = "Hey there ";
        break;
    }
    const numExclamations = this.vars.get<number>("num-exclamations");
    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
      <style>${css}</style>
			<div class="hhds-hello-world">        
        <h1><slot name="start"></slot>${greetingText}<slot></slot>${"!".repeat(numExclamations)}<slot name="end"></slot></h1>
      </div>
		`;

    //this.observeSlotChanges(true);
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    //this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  greeting: {
    description: "Type type of greeting to display.",
    defaultValue: GreetingType.Friendly,
    typeString: "GreetingType",
    type: GreetingType,
  },
  "num-exclamations": {
    description: "Number of exclamation marks to append to the greeting.",
    defaultValue: 2,
    type: Number,
  },
};
