import css from './tile.scss?inline';
import globalStyles from '../../index.scss?inline';	
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import { HHDSTileGridSource } from '../TileGrid/TileGridType';

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSTile';
const TAG_NAME: string = 'hhds-tile';
export const HHDSTileTagName: string = "hhds-tile";

// ////////////////////////////////////////////////////////////////////

export class HHDSTile extends HTMLElement {

  private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;	

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {

		this.parseAttributes();

		this.render();

		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
	}

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
	}

	render() {
    	const date = this.vars.get<string>("date"),
			  label = this.vars.get<string>("label"),
    		  description = this.vars.get<string>("description"),
    		  url = this.vars.get<string>("url"),
    		  target = this.vars.get<string>("target");

		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-tile hhds-tile__${this.vars.get('source')}">
				${url ? `<a href="${url}" ${target ? `target="${target}"` : ""} class="hhds-tile__link"></a>` : ""}
				<slot name="image"></slot>
				<div>
					${date ? 
						`<div class="hhds-tile__date">
							<time class="ui">${date}</time>
							${target === '_blank' ? `<slot name="icon"></slot>` : ""}
						</div>` : ""
					}
					${label ? `<p class="hhds-tile__label tile">${label}${date ? '' : '<slot name="icon"></slot>'}</p>` : ""}
					${description ? `<p class="hhds-tile__description tile">${description}</p>` : ""}
				</div>
				<slot name="tags"></slot>
			</div>
		`;
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}

}

export const ArgSpecs: ArgSpecDictionary = {
  "date": {
    description: "The date to display in the tile. This can be used for any text in the date style, such as is the case on the Community Category grid).",
    defaultValue: "",
    type: String,
  },
  "source": {
    description: "The type of tile. This is used for custom styling of the Insights tile at xs breakpoints.",
    defaultValue: "",
    type: HHDSTileGridSource,
  },
  "post": {
    description: "ID of a post associated with this tile. This is used in the People grid to fetch content for the modals.",
    defaultValue: "",
    type: String,
  },
  "label": {
    description: "The label text.",
    defaultValue: "",
    type: String,
  },
  "description": {
    description: "Additional textual information.",
    defaultValue: "",
    type: String,
  },
  "url": {
    description: "An optional URL to navigate to when the item is clicked.",
    defaultValue: "",
    type: String,
  },
  "target": {
    description: "Specifies where to open the URL (e.g., _blank for a new tab) if a URL is defined.",
    defaultValue: "",
    type: String,
  },
};

